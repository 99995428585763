import React, {FC, Suspense, useEffect, useRef, useState} from 'react';
import {BrowserRouter, Redirect, Route, RouteProps, Switch, useHistory, useLocation} from 'react-router-dom';
import axios from 'axios';
import {useRecoilValue, useResetRecoilState} from 'recoil';
import {UserSessionState, UserSessionStateProps} from './utils/state/UserSessionState';
import {useGA} from './utils/tracking/useGA';
import {CartContextProvider} from './utils/hooks/useCart';
import {LoadingAnimation} from './components/common/LoadingAnimation';
import {useNavigationRoot} from './utils/context/AppRootContext';

const Layout = React.lazy(() => import('./components/common/Layout'));
const JobList = React.lazy(() => import('./pages/jobs/JobList'));
const OffersPage = React.lazy(() => import('./pages/offers/OffersPage'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const PageNotFound = React.lazy(() => import('./pages/404/PageNotFound'));
const Registration = React.lazy(() => import('./pages/auth/Registration'));
const VerifyEmail = React.lazy(() => import('./pages/auth/VerifyEmail'));
const PortalRouter = React.lazy(() => import('./pages/portal/PortalRouter'));
const CheckoutRouter = React.lazy(() => import('./pages/checkout/CheckoutRouter'));
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'));
const NewPassword = React.lazy(() => import('./pages/auth/NewPassword'));
const Job = React.lazy(() => import('./pages/jobs/Job'));
const SuccessfulRegistration = React.lazy(() => import('./pages/auth/SuccessfulRegistration'));
const SuccessfulPasswordReset = React.lazy(() => import('./pages/auth/SuccessfulPasswordReset'));
const PrivacyPage = React.lazy(() => import('./pages/legal/Privacy'));
const ImprintPage = React.lazy(() => import('./pages/legal/Imprint'));
const Feedback = React.lazy(() => import('./pages/feedback/Feedback'));
const SuccessfulNewPasswordSet = React.lazy(() => import('./pages/auth/SuccessfulNewPasswordSet'));
const SuccessfulFeedback = React.lazy(() => import('./pages/feedback/SuccessfulFeedback'));
const SuccessfulContact = React.lazy(() => import('./pages/contactFormular/SuccessfulContact'));
const NewsLetterOptInPage = React.lazy(() => import('./pages/newsletter/OptInSuccess'));
const NewsLetterOptOutPage = React.lazy(() => import('./pages/newsletter/OptOutSuccess'));
const Conditions = React.lazy(() => import('./pages/legal/Conditions'));
const NewsletterSignUp = React.lazy(() => import('./pages/newsletter/NewsletterSignUp'));
const NewsletterSignUpConfirmation = React.lazy(() => import('./pages/newsletter/NewsletterSignUpConfirmation'));
const Faq = React.lazy(() => import('./pages/faq/Faq'));
const Participate = React.lazy(() => import('./pages/raffle/Participate'));
const ParticipateSuccessPage = React.lazy(() => import('./pages/raffle/ParticipateSuccessPage'));
const Homepage = React.lazy(() => import('./pages/home/Homepage'));
const ContactForm = React.lazy(() => import('./pages/contactFormular/ContactForm'));


export const AuthenticatedRoute: FC<RouteProps> = (props) => {
    const userSessionState = useRecoilValue<UserSessionStateProps>(UserSessionState);
    const location = useLocation();

    if (userSessionState?.user && userSessionState?.session) {
        return (
            <Route {...props} />
        );
    }

    return (
        <Route path={props.path} exact={props.exact}>
            <Redirect to={{pathname: '/auth/login', state: {redirect: location.pathname}}}/>
        </Route>
    );
};

const ScrollToTopHandler: FC = () => {
    const location = useLocation();

    useEffect(() => {
        // console.log('PATHNAME CHANGED', location.pathname);
        window.scrollTo({top: 0});
    }, [location.pathname]);

    return (
        <></>
    );
};

const PageViewTracker: FC = () => {
    const location = useLocation();
    const ga = useGA();

    useEffect(() => {
        if (ga && location) {
            ga.trackPageView(location.pathname);
        }
    }, [ga, location.pathname]);

    return (
        <></>
    );
};

export const App = () => {
    const history = useHistory();
    const userSessionState = useRecoilValue(UserSessionState);
    const resetSessionState = useResetRecoilState(UserSessionState);
    const [appIsLoading, setAppIsLoading] = useState<boolean>(true);
    const authTokenRef = useRef<string | null>(null);
    const navigationRoot = useNavigationRoot();

    useEffect(() => {
        authTokenRef.current = userSessionState?.session?.token || null;    // set auth token
        setAppIsLoading(false);
    }, [userSessionState]);

    useEffect(() => {
        const requestInterceptor = axios?.interceptors?.request?.use(
            (config) => ({
                ...config,
                headers: {
                    ...config?.headers,
                    ...(authTokenRef.current ? {Authorization: `Bearer ${authTokenRef.current}`} : {}),
                },
            }),
            (error) => {
                throw error;
            },
        );

        const responseInterceptor = axios?.interceptors?.response?.use(function (response) {
            return response;
        }, function (error) {
            // TODO: report error to sentry
            if (error?.response?.status === 401) {
                resetSessionState();
                history?.push('/auth/login');
            }
            throw error;
        });

        return () => {
            axios?.interceptors?.request?.eject(requestInterceptor);
            axios?.interceptors?.response?.eject(responseInterceptor);
        };
    }, []);

    return (
        <>
            {
                !appIsLoading ? (
                    <Suspense fallback={<LoadingAnimation fullScreen/>}>
                        <BrowserRouter basename={navigationRoot || '/'}>
                            <ScrollToTopHandler/>
                            <PageViewTracker/>
                            <CartContextProvider>
                                <Layout>
                                    <Switch>
                                        <Route path={'/'} exact component={Homepage}/>
                                        <Route path={'/jobs'} exact component={JobList}/>
                                        <Route path={'/job/:slug'} exact component={Job}/>
                                        <Route path={'/job/:slug/feedback'} exact component={Feedback}/>
                                        <Route path={'/job/:slug/feedback/success'} component={SuccessfulFeedback}/>
                                        <Route path={'/offers'} component={OffersPage}/>

                                        <Route path={'/auth/login'} component={Login}/>
                                        <Route path={'/auth/registration'} component={Registration}/>
                                        <Route path={'/auth/successful-registration'}
                                               component={SuccessfulRegistration}/>
                                        <Route path={'/auth/successful-password-reset'}
                                               component={SuccessfulPasswordReset}/>
                                        <Route path={'/auth/successful-password-set'}
                                               component={SuccessfulNewPasswordSet}/>
                                        <Route path={'/auth/verify-email'} component={VerifyEmail}/>
                                        <Route path={'/auth/reset-password'} component={ResetPassword}/>
                                        <Route path={'/auth/new-password'} component={NewPassword}/>

                                        <Route path={'/newsletter/sign-up'} exact component={NewsletterSignUp}/>
                                        <Route path={'/newsletter/sign-up/confirmation'}
                                               component={NewsletterSignUpConfirmation}/>
                                        <Route path={'/newsletter/opt-in'} component={NewsLetterOptInPage}/>
                                        <Route path={'/newsletter/opt-out'} component={NewsLetterOptOutPage}/>

                                        <AuthenticatedRoute path={'/portal'} component={PortalRouter}/>
                                        <AuthenticatedRoute path={`/checkout`} component={CheckoutRouter}/>

                                        <Route path={`/faq`} component={Faq}/>
                                        <Route path={'/contact/success'} component={SuccessfulContact}/>
                                        <Route path={'/contact'} component={ContactForm}/>

                                        <Route path={`/impressum`} component={ImprintPage}/>
                                        <Route path={`/datenschutz`} component={PrivacyPage}/>
                                        <Route path={`/agb`} component={Conditions}/>

                                        <Route path={'/obstkorb'} exact component={Participate}/>
                                        <Route path={'/obstkorb/signup-confirmation'}
                                               component={ParticipateSuccessPage}/>

                                        <Route path={''} component={PageNotFound}/>
                                    </Switch>
                                </Layout>
                            </CartContextProvider>
                        </BrowserRouter>
                    </Suspense>
                ) : <LoadingAnimation fullScreen/>
            }
        </>
    );
};
