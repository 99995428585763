import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export interface PriceProps {
  centAmount: number;
  currencyCode: string;
  fractionDigits: number;
  type: string;
}

export interface TaxProps {
  name: string;
  rate: number;
  amount: PriceProps;
}

export interface LineItemProps {
  productId: string;
  quantity: number;
  extraInfo?: Partial<{
    jobAdId: number;
  }>;
}

export interface CartPriceProps {
  price: string;
  tax: string;
  taxRate: string;
  discount: {
    discount: string;
    isInvalid: boolean;
    message: string | null;
  };
  totalPrice: string;
  discountProUser?: string;
}

export interface CartProps {
  id: string;
  //currency: string;
  userId?: string | null;
  anonymousId?: string | null;
  lineItems: LineItemProps[];
  couponId?: string | null;
  price: CartPriceProps & {
    itemPrices: (CartPriceProps & { productId: string })[]
  };
}

export type CartStateProps = CartProps | null;

export const CartState = atom<CartStateProps>({
  key: 'cart-state',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const CartVisibilityState = atom<boolean>({
  key: 'cart-visibility',
  default: false,
});
