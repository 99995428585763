import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
// import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/GlobalStyle';
import { Theme } from './styles/Theme';
import { MetaTags } from './components/common/MetaTags';
import { AppRootContextProvider } from './utils/context/AppRootContext';
import axios from 'axios';

const rootTag = document.getElementById('root');
if (rootTag) {
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <AppRootContextProvider value={{ apiPrefix: '/api', navigationRoot: '/' }}>
            <App />
            <MetaTags />
          </AppRootContextProvider>
        </ThemeProvider>
      </RecoilRoot>
    </React.StrictMode>,
    rootTag,
  );
}

const boomerJobsIntegrationTag = document.getElementById('agentur-boomer-jobs');
if (boomerJobsIntegrationTag) {
  const apiPrefix = boomerJobsIntegrationTag.getAttribute('data-api-prefix');
  const navigationRoot = boomerJobsIntegrationTag.getAttribute('data-navigation-root');

  // api interceptor
  axios.interceptors.request.use((value) => {
    const defaultPrefix = '/api/';
    if (value.url?.startsWith(defaultPrefix)) {
      value.url = `${apiPrefix}/${value.url?.substr(defaultPrefix.length)}`;
    }
    return value;
  });

  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '.25rem 1rem', background: 'red', color: 'white' }}>boomerjobs integration</div>
          <AppRootContextProvider value={{ apiPrefix, navigationRoot }}>
            <App />
            <MetaTags />
          </AppRootContextProvider>
        </ThemeProvider>
      </RecoilRoot>
    </React.StrictMode>,
    boomerJobsIntegrationTag,
  );
}


// reportWebVitals();
