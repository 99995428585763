import React, { useContext } from 'react';

interface IAppRootContext {
  apiPrefix: string | null;
  navigationRoot: string | null;
}

const AppRootContext = React.createContext<Partial<IAppRootContext>>({});

export const AppRootContextProvider = AppRootContext.Provider;

export const useAppRootContext = () => useContext(AppRootContext);
export const useApiPrefix = () => useAppRootContext().apiPrefix || '/api';
export const useNavigationRoot = () => useAppRootContext().navigationRoot || '/';