import { atom } from 'recoil';
import { ReactNode } from 'react';

export interface PopUpStateProps {
  popUp: PopUpProps | null;
}

export interface PopUpProps {
  opacity?: string;
  closeOnBackgroundClick?: boolean;
  loading?: boolean;
  background?: string;
  headline: string;
  text: string;
  socialMedia?: {
    title?: string;
    company?: string;
    url?: string;
  }
  buttons?: {
    acceptText?: string | ReactNode | ((state: PopUpProps) => ReactNode);
    declineText?: string | ReactNode | ((state: PopUpProps) => ReactNode);
    acceptAction?: () => void;
    declineAction?: () => void;
  };
  renderChildren?: () => ReactNode;
}

export const PopUpState = atom<PopUpStateProps>({
  key: 'popUpState',
  default: {
    popUp: null,
  },
});