import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ImageProps } from '../interfaces/Image';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const { persistAtom } = recoilPersist();

export interface AddressProps {
  id?: number;
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  company: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
  vatId: string;
}

export interface UserProps {
  id?: string;
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  isEmailVerified?: boolean;
  address: AddressProps;
  logo: ImageProps | null;
  isProfessional?: boolean;
}

export interface SessionProps {
  token: string;
}

export interface UserSessionStateProps {
  user: UserProps | null;
  session: SessionProps | null;
}

export const UserSessionState = atom<UserSessionStateProps>({
  key: 'user-session-state',
  default: {
    user: null,
    session: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useCurrentUser = () => {
  const userIdRef = useRef<string | null>(null);
  const [userSessionState, setUserSessionState] = useRecoilState(UserSessionState);
  const [userLoading, setUserLoading] = useState(false);

  const reloadUserSessionState = () => {
    if (userSessionState.user?.id) {
      setUserLoading(true);
      axios.get(`/api/customer/${userSessionState?.user?.id}`)
        .then((response) => {
          setUserLoading(false);
          if (response.data) {
            setUserSessionState((state) => ({
              ...state,
              user: response.data,
            }));
          }
        })
        .catch(() => {
          setUserLoading(false);
        });
    }
  };

  const patchUserData = (data: Partial<UserProps>) => {
    if (userSessionState.user?.id) {
      setUserLoading(true);
      return axios.patch(`/api/customer/${userSessionState?.user?.id}`, { ...data })
        .then((response) => {
          setUserLoading(false);
          if (response.data) {
            setUserSessionState((state) => ({
              ...state,
              user: response.data,
            }));
          }
          return true;
        })
        .catch((error) => {
          setUserLoading(false);
          throw error;
        });
    }
    throw new Error('no current user');
  };

  const patchUserLogo = (logo: any) => {
    if (userSessionState.user?.id) {
      setUserLoading(true);
      return axios.post(`/api/customer/${userSessionState?.user?.id}/logo`, logo, {
        headers: {
          'accept': 'application/json',
          'Content-Type': `multipart/form-data`,
        },
      })
        .then(() => {
          setUserLoading(false);
          return true;
        })
        .catch((error) => {
          setUserLoading(false);
          throw error;
        });
    }
    throw new Error('no current user');
  };

  const patchUserPassword = (password: string) => {
    if (userSessionState.user?.id) {
      setUserLoading(true);
      return axios.patch(`/api/customer/${userSessionState?.user?.id}/password`, { password })
        .then(() => {
          setUserLoading(false);
          return true;
        })
        .catch((error) => {
          setUserLoading(false);
          throw error;
        });
    }
    throw new Error('no current user');
  };

  useEffect(() => {
    const userId = userSessionState.user?.id || '';
    if (userIdRef.current !== userId) {
      userIdRef.current = userId;
      reloadUserSessionState();
    }
  }, [userSessionState]);

  return {
    userSessionState,
    userLoading,
    reloadUserSessionState,
    patchUserData,
    patchUserLogo,
    patchUserPassword,
  };
};
