import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

interface ITrackingEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

const EmptyState: IGAState = {
  trackPageView: () => {
    console.log('Tracking Page View', 'not allowed');
  },
  trackEvent: () => {
    console.log('Tracking Event', 'not allowed');
  },
};

interface IGAState {
  trackPageView: (url: string) => void;
  trackEvent: (props: ITrackingEvent) => void;
}

const GAState = atom<IGAState>({
  key: 'TrackingGAState',
  default: {
    ...EmptyState,
  },
});

export const useInitGA = () => {
  const setState = useSetRecoilState(GAState);
  return (trackingAllowed: boolean) => {
    const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';

    // console.log('GA TRACKING ALLOWED', trackingAllowed);

    if (!!trackingId && trackingAllowed) {
      ReactGA.initialize(trackingId, { testMode: process.env.NODE_ENV === 'development' });
      setState({
        trackPageView: (url: string) => {
          ReactGA.send({ hitType: 'pageview', page: url, title: 'Pageview' });
        },
        trackEvent: (props: ITrackingEvent) => {
          ReactGA.event(props);
        },
      });
    } else {
      setState(EmptyState);
    }
  };
};

export const useGA = () => useRecoilValue(GAState);

export const useGAPageViewTracking = () => {
  const ga = useGA();
  useEffect(() => {
    ga.trackPageView(`${window.location.pathname}`);
  }, [ga]);
};
