import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { PaymentMethod } from '@stripe/stripe-js';

const { persistAtom } = recoilPersist();

export type SelectedPaymentMethodProps = PaymentMethod | null;

export const SelectedPaymentMethodState = atom<SelectedPaymentMethodProps>({
  key: 'selected-payment-method',
  default: null,
  effects_UNSTABLE: [persistAtom],
});