import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { SetterOrUpdater, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { UserSessionState, UserSessionStateProps } from '../state/UserSessionState';
import { CartState, CartStateProps, CartVisibilityState } from '../state/CartState';
import { ProductProps } from '../interfaces/Product';
import { SelectedPaymentMethodState } from '../state/SelectedPaymentMethod';
import { PaymentMethodsState } from '../state/PaymentMethodsState';
import { PopUpState } from '../state/PopUpState';

interface CartContextProps {
  cart: CartStateProps;
  cartIsLoading: boolean;
  itemCount: number;
  cartVisibility: boolean;
  setCartIsLoading: SetterOrUpdater<boolean>;
  setCartVisibility: SetterOrUpdater<boolean>;
  products: ProductProps[] | null;
  addToCart: (lineItem: { quantity: number, id: number, extraInfo?:any }) => Promise<AxiosResponse>;
  removeLineItem: (lineItem: string, extra?: string) => Promise<AxiosResponse>;
  changeLineItemQuantity: (lineItem: string, quantity: number) => Promise<AxiosResponse>;
  addDiscount: (code: string) => Promise<AxiosResponse>;
  removeDiscount: (discountId: string) => Promise<AxiosResponse>;
  commitPayment: (paymentMethodId?: string) => Promise<AxiosResponse>;
}

const CartContext = React.createContext<Partial<CartContextProps>>({});

export const CartContextProvider: FC = (props) => {
  const userIdRef = useRef<string | null>(null);
  const userCountryRef = useRef<string | null>(null);
  const userSessionState = useRecoilValue<UserSessionStateProps>(UserSessionState);

  const [cart, setCart] = useRecoilState<CartStateProps>(CartState);
  const [products, setProducts] = useState<ProductProps[] | null>(null);
  const [itemCount, setItemCount] = useState<number>(0);
  const [cartVisibility, setCartVisibility] = useRecoilState<boolean>(CartVisibilityState);
  const [cartIsLoading, setCartIsLoading] = useState<boolean>(false);
  const [cartProductsLoading, setCartProductsLoading] = useState<boolean>(false);
  const blockCartRef = useRef<boolean>(false);

  const setPopUpState = useSetRecoilState(PopUpState);

  const selectedPaymentMethod = useRecoilValue(SelectedPaymentMethodState);
  const resetSelectedPaymentMethodState = useResetRecoilState(SelectedPaymentMethodState);
  const resetPaymentMethodsState = useResetRecoilState(PaymentMethodsState);

  const fetchProduct = async (productId: string) => {
    const product = await axios.get(`/api/product/${productId}`);
    return product.data;
  };

  const fetchProducts = async (lineItems: { productId: string }[]) => {
    return await Promise.all([
      ...lineItems.map(async (lineItem) => {
        return await fetchProduct(lineItem.productId);
      }),
    ]) || [];
  };

  const fetchCart = async (cartId?: string) => {
    const url = cartId ? `/api/cart/${cartId}` : userSessionState.user?.id ? `/api/cart/customer/${userSessionState.user?.id}` : null;
    if (!url) {
      throw new Error('No cart available.');
    }
    return await axios.get(url);
  };

  const refreshCart = (cartId?: string) => {
    setCartIsLoading(true);
    fetchCart(cartId)
      .then((response) => {
        // console.log("CART", response.data);
        setCart(response.data || null);
        setCartIsLoading(false);
      })
      .catch((error) => {
        console.log('CART', 'error fetching cart', error);
        handleCartError(error);
        setCartIsLoading(false);
        if (!error?.response?.data?.couponId) {
          setCart(null);
        }
      });
  }

  const handleCartError = (error: AxiosError) => {
    const { status, data = {} } = error?.response || {};
    if (data.couponId) {
      console.log('CART', 'coupon code', data.couponId, 'expired');

      // show coupon expired error message
      setPopUpState({
        popUp: {
          headline: 'Coupon Code',
          text: `Der Coupon Code ${data.couponId} ist abgelaufen. Du kannst ihn nicht bei deiner Bestellung verwenden.`,
          closeOnBackgroundClick: false,
          buttons: {
            acceptText: 'Verstanden',
            acceptAction: () => {
              setPopUpState({ popUp: null });
              if (cart?.id) {
                refreshCart(cart.id);
              }
            },
          },
        },
      });
    }

    if (status === 403 && data.payment?.status) {
      // show payment method declined error message
      setPopUpState({
        popUp: {
          headline: 'Zahlung',
          text: `Die Zahlung wurde von deinem Zahlungsprovider abgelehnt. Bitte versuche eine andere Zahlungsmethode.`,
          closeOnBackgroundClick: false,
          buttons: {
            acceptText: 'Verstanden',
            acceptAction: () => {
              setPopUpState({ popUp: null });
              if (cart?.id) {
                refreshCart(cart.id);
              }
            },
          },
        },
      });
    }

    if (status === 400 && data.payment?.status) {
      // show payment method needed error message
      setPopUpState({
        popUp: {
          headline: 'Zahlung',
          text: `Bitte hinterlege eine Zahlungsart, um diese Bestellung durchzuführen.`,
          closeOnBackgroundClick: false,
          buttons: {
            acceptText: 'Verstanden',
            acceptAction: () => {
              setPopUpState({ popUp: null });
              if (cart?.id) {
                refreshCart(cart.id);
              }
            },
          },
        },
      });
    }
  };

  const addToCart = async (lineItem: { quantity: number, id: number, extraInfo?: any }) => {
    if (blockCartRef.current) {
      throw new Error('cart busy');
    }
    blockCartRef.current = true;
    setCartIsLoading(true);
    return await axios.post('/api/cart/add', {
      lineItems: [lineItem],
      customerId: (userSessionState && userSessionState.user && userSessionState.user.id) ? userSessionState.user.id : null,
      anonymousId: (cart && cart.anonymousId) ? cart.anonymousId : null,
      cartId: (cart && cart.id) ? cart.id : null,
    })
      .then((res) => {
        setCartIsLoading(false);
        if (res.data) {
          setCart(res.data || null);
        }
        return res;
      })
      .catch((err) => {
        setCartIsLoading(false);
        handleCartError(err);
        blockCartRef.current = false;
        throw err;
      });
  };

  const removeLineItem = async (lineItem: string, extra?: string) => {
    setCartIsLoading(true);
    return await axios.delete(`/api/cart/${cart?.id}/line-item/${lineItem}${extra ? `/${extra}` : ''}`)
      .then((res) => {
        setCartIsLoading(false);
        if (res.data) {
          setCart(res.data || null);
        }
        return res;
      })
      .catch((err) => {
        setCartIsLoading(false);
        handleCartError(err);
        throw err;
      });
  };

  const changeLineItemQuantity = async (lineItem: string, quantity: number) => {
    setCartIsLoading(true);
    return await axios.patch(`/api/cart/${cart?.id}/line-item/${lineItem}`, { quantity })
      .then((res) => {
        setCartIsLoading(false);
        if (res.data) {
          setCart(res.data || null);
        }
        return res;
      })
      .catch((err) => {
        setCartIsLoading(false);
        handleCartError(err);
        throw err;
      });
  };

  const addDiscount = async (code: string) => {
    setCartIsLoading(true);
    return await axios.post(`/api/cart/${cart?.id}/discount/add`, { code })
      .then((res) => {
        setCartIsLoading(false);
        if (res.data) {
          setCart(res.data || null);
        }
        return res;
      })
      .catch((err) => {
        setCartIsLoading(false);
        handleCartError(err);
        throw err;
      });
  };

  const removeDiscount = async (discountId: string) => {
    setCartIsLoading(true);
    return await axios.post(`/api/cart/${cart?.id}/discount/remove`, { discountId })
      .then((res) => {
        setCartIsLoading(false);
        if (res.data) {
          setCart(res.data || null);
        }
        return res;
      })
      .catch((err) => {
        setCartIsLoading(false);
        handleCartError(err);
        throw err;
      });
  };

  const commitPayment = async (paymentMethodId?: string) => {
    if (blockCartRef.current) {
      throw new Error('cart busy');
    }
    blockCartRef.current = true;
    setCartIsLoading(true);
    return await axios.post('/api/payment/pay', {
      cartId: cart?.id,
      customerId: userSessionState?.user?.id,
      paymentMethod: paymentMethodId || undefined,
    })
      .then((res) => {
        setCartIsLoading(false);
        if (res.data) {
          setCart(null);    // reset cart
        }
        return res;
      })
      .catch((err) => {
        setCartIsLoading(false);
        handleCartError(err);
        blockCartRef.current = false;
        throw err;
      });
  };

  const getItemCount = () => {
    if (cart?.lineItems?.length) {
      return cart.lineItems.reduce((sum, lineItem) => {
        return sum + lineItem.quantity;
      }, 0);
    }

    return 0;
  };

  useEffect(() => {
    setItemCount(getItemCount());
  }, [cart?.lineItems]);

  useEffect(() => {
    blockCartRef.current = false;
    if (cart) {
      setCartProductsLoading(true);
      fetchProducts([...(cart.lineItems || []).map((lineItem: any) => {
        return {
          productId: lineItem.productId,
        };
      })]).then((products) => {
        setProducts(products);
        setCartProductsLoading(false);

        // check selected payment method
        const onlyFlatratesInCart = (cart?.lineItems || []).every((item) => products?.find(({ id }) => id.toString() === item.productId.toString())?.productType === 'FLATRATE');
        if (selectedPaymentMethod?.type === 'invoice' && !onlyFlatratesInCart) {    // allow payment method invoice for flatrates only
          resetSelectedPaymentMethodState();   // unselect payment method invoice
        }
      }).catch((error) => {
        setCartProductsLoading(false);
      });
    } else {
      setProducts([]);
    }
  }, [cart]);

  useEffect(() => {
    const userId = userSessionState.user?.id || '';
    const userCountry = userSessionState.user?.address?.country || '';
    if (userIdRef.current !== userId || userCountryRef.current !== userCountry) {     // update cart if user id or user country changed
      userIdRef.current = userId;
      userCountryRef.current = userCountry;

      if (!userSessionState?.user && cart?.userId) {
        setCart(null);
      }

      // reset payment method states if not logged in
      if (!userSessionState?.user) {
        resetSelectedPaymentMethodState();
        resetPaymentMethodsState();
      }

      const cartId = cart && (!cart.userId && !userSessionState?.user?.id || cart.userId === `${userSessionState?.user?.id}`) && cart.id || undefined;
      if (cartId || userSessionState?.user?.id) {
        refreshCart(cartId);
      } else {
        setCart(null);
      }
    }
  }, [userSessionState]);

  const context = {
    cart,
    cartIsLoading,
    cartProductsLoading,
    products,
    itemCount,
    cartVisibility,
    setCartVisibility,
    addToCart,
    removeLineItem,
    changeLineItemQuantity,
    addDiscount,
    removeDiscount,
    commitPayment,
  };

  return (<CartContext.Provider value={context}>{props.children}</CartContext.Provider>);
};

export const useCart = () => {
  const cartContext = useContext(CartContext);

  return {
    ...cartContext as CartContextProps,
  };
};
