import { createGlobalStyle } from 'styled-components';
import { ColorTheme, getThemeFont } from './Theme';

export const GlobalStyle = createGlobalStyle`
  ${ColorTheme};

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${getThemeFont('default')};
    position: relative;
    -webkit-overflow-scrolling: touch;      /* important for swiping on ios */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;

    &.no-scroll {
      overflow: hidden;
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  input {
    border-radius: 0;
  }
  
  select, option {
    color: inherit;
  }
`;