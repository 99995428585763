import { atom, useRecoilState } from 'recoil';
import { PaymentMethod } from '@stripe/stripe-js';
import { useEffect } from 'react';
import axios from 'axios';
import { SelectedPaymentMethodState } from './SelectedPaymentMethod';
import { useCart } from '../hooks/useCart';
// import { recoilPersist } from 'recoil-persist';

// const { persistAtom } = recoilPersist();

type PaymentMethodsStateProps = {
  paymentMethods: PaymentMethod[] | null;
  lastUpdate: Date | null;
}

export const PaymentMethodsState = atom<PaymentMethodsStateProps>({
  key: 'payment-methods',
  default: {
    paymentMethods: null,
    lastUpdate: null,
  },
  // effects_UNSTABLE: [persistAtom],
});

export const usePaymentMethods = (init = true) => {
  const { cart, products } = useCart();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useRecoilState(SelectedPaymentMethodState);
  const [paymentMethodsState, setPaymentMethodsState] = useRecoilState(PaymentMethodsState);

  const fetchPaymentMethods = (resetState?: boolean) => {
    if (resetState) {
      setPaymentMethodsState((state) => ({
        ...state,
        paymentMethods: null,
        lastUpdate: null,
      }));
    }
    axios.get(`/api/payment/payment-methods`)
      .then((response) => {
        const onlyFlatratesInCart = (cart?.lineItems || []).every((item) => products?.find(({ id }) => id.toString() === item.productId.toString())?.productType === 'FLATRATE');
        const paymentMethods: PaymentMethod[] = (response.data || []).filter(({ type }: PaymentMethod) => type !== 'invoice' || onlyFlatratesInCart);   // allow payment method invoice for flatrates only

        // console.log('FETCH PAYMENT METHODS', response.data);
        setPaymentMethodsState((state) => ({
          ...state,
          paymentMethods,
          lastUpdate: new Date(),
        }));
      })
      .catch((error) => {
        console.log('FETCH PAYMENT METHODS ERROR', error);
        setPaymentMethodsState((state) => ({
          ...state,
          paymentMethods: [],
          lastUpdate: new Date(),
        }));
      });
  };

  useEffect(() => {
    if (init) {
      fetchPaymentMethods();
    }
  }, [init]);

  useEffect(() => {
    if (
      selectedPaymentMethod?.id && paymentMethodsState.paymentMethods &&
      !paymentMethodsState.paymentMethods.map(({ id }) => id).includes(selectedPaymentMethod.id)
    ) {
      setSelectedPaymentMethod(null);   // unselect removed payment method
    }
  }, [paymentMethodsState]);

  useEffect(() => {
    fetchPaymentMethods(true);
  }, [cart?.lineItems]);

  return {
    ...paymentMethodsState,
    fetchPaymentMethods,
  };
};