import { FC } from 'react';
import { Helmet } from 'react-helmet';

interface MetaTagsProps {
  hideTitleTemplate?: boolean;
  metaTitle?: string;
  metaDescription?: string | null;
  metaKeywords?: string | null;
  metaCanonical?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImage?: string;
  ogImageType?: string;
  ogImageAlt?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterUrl?: string;
  twitterImage?: string;
}

export const MetaTags: FC<MetaTagsProps> = (metaTagsProps) => {

  const {
    hideTitleTemplate = false,
    metaTitle = 'Jobportal',
    metaDescription = 'Finde ganz einfach Stellenangebote für Berufe in Werbung, Medien, Marketing und IT',
    metaKeywords = 'Jobbörse, Stellenanzeigen, Stellenangebote, Jobportal, Agentur Boomer, Jobs, Berufe, Vollzeit, Halbtags, Marketing, Werbung, IT, Digitale Berufe, Remote Jobs, Homeoffice Jobs',
    metaCanonical = window.location.origin + window.location.pathname,
    ogTitle = metaTitle,
    ogDescription = metaDescription,
    ogUrl = metaCanonical + '?utm_medium=opengraph',
    ogImage = 'https://agentur-bommer-jobs.fra1.digitaloceanspaces.com/opengraph-image.jpg',
    ogImageType = 'image/jpeg',
    ogImageAlt = 'Jobportal | Agentur Boomer',
    twitterTitle = ogTitle,
    twitterDescription = ogDescription,
    twitterUrl = metaCanonical + '?utm_medium=twitter',
    twitterImage = ogImage,
  } = metaTagsProps;

  const titleEnd = !hideTitleTemplate ? ' | Agentur Boomer' : '';

  return (
    <Helmet
      encodeSpecialCharacters={true}
      defaultTitle={metaTitle}
    >

      <title itemProp="name" lang="de">{metaTitle + titleEnd}</title>
      <meta name="description" content={metaDescription || ''} />
      <meta name="keywords" content={metaKeywords || ''} />

      <link rel="canonical" href={metaCanonical} />

      <meta property="og:title" content={ogTitle + titleEnd} />
      <meta property="og:description" content={ogDescription || ''} />
      <meta property="og:site_name" content="Agentur Boomer | Jobportal" />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:type" content={ogImageType} />
      <meta property="og:image:alt" content={ ogImageAlt?.length > 0 ? ogImageAlt : 'Agentur Boomer | Jobportal'} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />

      <meta name="twitter:title" content={twitterTitle + titleEnd} />
      <meta name="twitter:description" content={twitterDescription || ''} />
      <meta name="twitter:url" content={twitterUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@AgenturBoomer" />
      <meta name="twitter:image" content={twitterImage} />

    </Helmet>
  );
};

// Example
// <MetaTags
//   metaTitle={'Custom metaTitle'}
//   metaDescription={'Custom metaDescription'}
//   metaKeywords={'Custom metaKeywords'}
//   metaCanonical={'https://boomerjobs.de/'}
//   ogTitle={'Custom ogTitle'}
//   ogDescription={'Custom ogDescription'}
//   ogUrl={'https://boomerjobs.de/?utm_medium=opengraph'}
//   ogImage={'Custom ogImage'}
//   ogImageType={'Custom ogImageType'}
//   ogImageAlt={'Custom ogImageAlt'}
//   twitterTitle={'Custom twitterTitle'}
//   twitterDescription={'Custom twitterDescription'}
//   twitterUrl={'https://boomerjobs.de/?utm_medium=twitter'}
//   twitterImage={'Custom twitterImage'}
// />