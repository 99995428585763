import styled from 'styled-components';
import { Flexbox } from '../../styles/Layouts';
import { FC } from 'react';
import { UIComponentProps } from '../../styles/UIComponentProps';

const LoadingWrapper = styled(Flexbox)<LoadingAnimationProps>`
  position: ${({ fullScreen }) => fullScreen ? 'fixed' : 'absolute'};
  justify-content: center;
  align-items: center;
  background: rgba(251,251,251,0.7);

  width: ${({ fullScreen }) => fullScreen ? '100vw' : '100%'};
  height: ${({ fullScreen }) => fullScreen ? '100vh' : '100%'};
  z-index: 10;
  left: 0;
  top: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LoadingGif = styled.img`
  width: 100%;
  max-width: 10rem;
  opacity: 1;
  z-index: 11;
`;

interface LoadingAnimationProps {
  fullScreen?: boolean;
}

export const LoadingAnimation: FC<LoadingAnimationProps & UIComponentProps> = (props) => {
  const {
    fullScreen = false
  } = props;

  return (
    <LoadingWrapper {...props}>
      <LoadingGif as={'object'} type="image/svg+xml" data={`${process.env.PUBLIC_URL}/images/agentur-boomer-kugel.svg`}>
        <LoadingGif src={`${process.env.PUBLIC_URL}/images/agentur-boomer-jobs-logo.gif`} />
      </LoadingGif>
    </LoadingWrapper>
  );
};